/**
 * 分页功能混入
 * 使用需要规定获取表格数据的方法命名为  @getList
 */

export default {
  data() {
    return {
      page: { current: 1, size: 10, total: 0, pages: 0, changePage: false, params: {} }
    }
  },
  computed: {
    // 分页选项
    sizes() {
      let { allPage = 1, size = 10, total = 1 } = this.page;
      let sizeList = [];
      let num = Math.ceil(total / 10);
      for (let i = 1; i <= num; i++) {
        sizeList.push(i * 10);
      }
      return sizeList;
    },
  },
  methods: {
    // 更改分页大小
    changeSize(size) {
      this.page.size = parseInt(size)
      this.page.current = 1
      this.page.changePage = false
      return this.getList();
    },
    // 切换分页
    changePage(page) {
      this.page.current = page;
      this.page.changePage = true
      return this.getList();
    },
    parsePage(res) {
      return {
        current: res.current,
        size: res.size,
        total: res.total,
        pages: res.pages,
      };
    },
    parseList(res) {
      let { records = [] } = res;
      records.forEach((item, index) => {
        item.tempId = (res.current - 1) * res.size + index + 1;
        if (item.risk == -1) {
          this.showRisk = false;
        }
      });
      return records;
    },
  },
};
