import API from "./index";

export default {
  // 通用接口
  get: (url, params) => {
    return API.get(
      {
        params,url:url, auth: true 
      },
      false
    )
  },
  post: (url, data) => {
    return API.post(
      {
        data,url:url, auth: true 
      },
      false
    )
  },
  page: (url, data) => {
    return API.post(
      {
        data,url:url+'/page', auth: true 
      },
      false
    )
  },
  list: (url, data) => {
    return API.post(
      {
        data,url:url+'/list', auth: true 
      },
      false
    )
  },
  add: (url, data) => {
    return API.post(
      {
        data,url:url+'/add', auth: true 
      },
      false
    )
  },
  edit: (url, data) => {
    return API.post(
      {
        data,url:url+'/edit', auth: true 
      },
      false
    )
  },
  detail: (url, id) => {
    return API.get(
      {
        params:{id},url:url+'/detail', auth: true 
      },
      false
    )
  },
  del: (url, id) => {
    return API.post(
      {
        data:{id},url:url+'/del', auth: true 
      },
      false
    )
  }
}
