<template>
  <el-drawer :title="title" :visible.sync="visible" direction="rtl" append-to-body @close="handleClose">
    <div class="form">
      <el-form :model="form" :rules="rules" ref="formRef" label-width="80px">
        <el-form-item label="录入类型" prop="type">
          <el-radio-group v-removeAriaHidden v-model="form.type">
            <el-radio v-for="(it, index) in typeList" :key="index" :label="it">{{ it }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idCard">
          <el-input v-model="form.idCard"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="tag">
          <!--出借人-->
          <el-radio-group v-if="form.type == '出借人'" v-model="form.tag">
            <el-radio v-for="(it, index) in lenderTags" :key="index" :label="it" />
          </el-radio-group>
          <!--黑名单-->
          <el-radio-group v-else v-model="form.tag">
            <el-radio v-for="(it, index) in blacklistTags" :key="index" :label="it" />
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="addOrEdit" :loading="loading">{{ loading ? '提交中 ...' : '确 定'
          }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import verify from '@/utils/verify'
import service from '@/api/common'

export default {
  data() {
    var checkIdCard = (rule, value, callback) => {
      if (!value || value === '') {
        callback()
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'))
      }
      callback();
    };
    var checkMobile = (rule, value, callback) => {
      if (!value || value === '') {
        callback()
      } else if (!verify.mobile(value)) {
        callback(new Error('请输入正确的手机号'))
      }
      callback()
    }

    return {
      prefix: "api/blacklist",
      title: '录入黑名单',
      visible: false,
      loading: false,
      form: { type: '出借人'},
      rules: {
        type: [{ required: true, message: '请选择录入类型', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        idCard: [{ required: false, validator: checkIdCard, trigger: 'blur' }],
        tag: [{ required: true, message: '请选择标签', trigger: 'blur' }],
        mobile: [{ validator: checkMobile, trigger: 'blur' }]
      },
      typeList: ['出借人', '黑名单'],
      lenderTags: ['真', '假', '刷', '疑'],
      blacklistTags: ['撸子', '逾期']
    }
  },
  methods: {
    handleClose() {
      this.form = { type: '出借人', tagList: [] }
    },
    open(visible,data) {
      this.visible = visible
      if(data){
        this.detail(data.id)
      }
    },
    async detail(id){
      let res = await service.detail(this.prefix,id)
      this.form = {...res}
    },
    addOrEdit() {
      let check = false;
      this.$refs.formRef.validate(res => (check = res))
      if (!check) return
      this.$confirm(`是否确认将用户<span style="color:red">${this.form.name}</span>录入?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        if(this.form.id==null){
          let result = await service.add(this.prefix, this.form)
        }else{
          let result = await service.edit(this.prefix, this.form)
        }
        this.$notify({ type: 'success', message: '录入成功！' })
        this.visible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>
<style scoped lang="scss">
.form {
  width: 95%;
  padding: 0 0 20px 20px;
}

.footer {
  text-align: center;
}

@media (max-width: 480px) {
  ::v-deep .el-drawer {
    width: 90% !important;
  }
}
</style>